import { Col, ConfigProvider, Row } from "antd";
// import { project } from "./model/project";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";

import { ProjectPicker } from "./components/project-picker";
import React from "react";
import cs_CZ from "antd/lib/locale-provider/cs_CZ";
import keycloak from "./keycloak";

const AppPage = () => {
  const { keycloak, initialized } = useKeycloak();

  React.useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak, initialized]);

  if (!keycloak.authenticated) {
    return <></>;
  }

  return (
    <Row>
      <Col span={6}></Col>
      <Col span={12}>
        <div id="logo">
          <div />
        </div>
      </Col>
      <Col span={6}></Col>
      <Col span={6}></Col>
      <Col span={12}>
        <ProjectPicker />
      </Col>
      <Col span={6}></Col>
    </Row>
  );
};

const App = () => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ checkLoginIframe: false }}
    >
      <ConfigProvider locale={cs_CZ}>
        <AppPage />
      </ConfigProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
